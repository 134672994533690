<style lang="scss">
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 77%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .custom-file-upload {
    padding: 6px 12px;
    cursor: pointer;
    background: #1bc5bd;
    color: white;
    border-radius: 3px;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="lg"
    v-model="showModal"
    :title="is_edit ? 'Update Service Code' : 'Create Service Code'"
  >
    <div class="row">
      <div class="col-6" style="padding: 10px;">
        <div class="form-group">
          <label for="">Title <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="servicecode.service_name"
            :class="errors['service_name'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['service_name']" class="text-danger">{{
            errors["service_name"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-6" style="padding: 10px;">
        <div class="form-group">
          <label for="">Code <span class="text-danger">*</span></label>
          <input
            type="text"
            v-model="servicecode.code"
            name=""
            class="form-control"
            id=""
            :class="errors['code'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['code']" class="text-danger">{{
            errors["code"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-4" style="padding: 10px;">
        <div class="form-group">
          <label for=""
            >Delete In(Days) <span class="text-danger">*</span></label
          >
          <input
            type="number"
            v-model="servicecode.days"
            name=""
            class="form-control"
            id=""
            :class="errors['days'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['days']" class="text-danger">{{
            errors["days"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-8"></div>

      <div class="col-md-4" style="padding: 10px;">
        <div class="form-group">
          <label for=""
            >Default Cost <span class="text-danger">*</span></label
          >
          <input
            type="number"
            v-model="servicecode.default_cost"
            name=""
            class="form-control"
            id=""
            :class="errors['default_cost'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['default_cost']" class="text-danger">{{
            errors["default_cost"][0]
          }}</span>
        </div>
        </div>
      <div class="col-md-4" style="padding: 10px;">
        <div class="form-group">
          <label for=""
            >Default Markup <span class="text-danger">*</span></label
          >
          <input
            type="number"
            v-model="servicecode.default_markup"
            name=""
            class="form-control"
            id=""
            :class="errors['default_markup'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['default_markup']" class="text-danger">{{
            errors["default_markup"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-4" style="padding: 10px;">
        <div class="form-group">
          <label for="">Default Tax <span class="text-danger">*</span></label>
          <input
            type="number"
            v-model="servicecode.default_tax"
            name=""
            class="form-control"
            id=""
            :class="errors['default_tax'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['default_tax']" class="text-danger">{{
            errors["default_tax"][0]
          }}</span>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
        <b-button
          v-if="!is_edit"
          @click="save()"
          variant="success"
          size="sm"
          class="float-right mr-5"
        >
          Save
        </b-button>
        <b-button
          v-if="is_edit"
          @click="save()"
          variant="success"
          size="sm"
          class="float-right mr-5"
        >
          Update
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import {} from "@/core/services/store/mutations.type";
import { CREATE_SERVICECODES } from "../../../core/services/store/actions.type";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    is_edit: {
      type: Boolean,
      default: false
    },
    servicecode: {
      type: Object,
      default: () => {
        return {
          service_name: "",
          id: 0,
          code: "",
          days: "",
          default_markup: "",
          default_tax: "",
          errors: []
        };
      }
    }
  },
  data() {
    return {
      showImage: false,
      errors: []
    };
  },

  components: {},
  computed: {
    ...mapGetters([])
  },

  methods: {
    close() {
      this.$emit("closemodal");
    },
    save() {
      this.$store
        .dispatch(CREATE_SERVICECODES, this.servicecode)
        .then(() => {
          if (!this.is_edit) {
            this.$toastr.s("Service code successfully created");
          } else {
            this.$toastr.s("Service code successfully updated");
          }
          this.$emit("closemodal");
        })
        .catch(err => {
          this.errors = err.data;
        });
    }
  }
};
</script>
