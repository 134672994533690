<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="col-md-12" style="background:white">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colspan="2">
                <input
                  v-model="searchInput"
                  type="text"
                  placeholder="Search Service Code"
                  class="form-control"
                />
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>

              <th colspan="2">
                <button
                  class="btn btn-info"
                  style="float: right"
                  @click="showModal"
                >
                  <i class="fa fa-plus"></i> Add Service Code
                </button>
              </th>
            </tr>

            <tr>
              <th>SN</th>
              <th>Name</th>
              <th>Code</th>
              <th>Delete In(Days)</th>
              <th>Default Cost</th>
              <th>Default Markup</th>
              <th>Default Tax</th>
              <th style="width:20%">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loadingData && filteredServiceCode.length > 0">
            <tr v-for="(item, index) in filteredServiceCode" :key="index">
              <td>{{ ++index }}</td>
              <td>{{ item.service_name }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.days }}</td>
              <td>{{item.default_cost }}</td>
              <td>{{ item.default_markup }}</td>
              <td>{{ item.default_tax }}</td>

              <td style="width:100px">
                <button
                  @click="edit(item)"
                  class="btn btn-sm btn-success mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  Edit
                </button>
              </td>
            </tr>
          </tbody>

          <tbody v-if="!loadingData && filteredServiceCode.length < 1">
            <tr>
              <td colspan="8" class="text-center">No record(s) found</td>
            </tr>
          </tbody>

          <tbody v-if="loadingData">
            <tr>
              <th class="text-center" colspan="10">
                <vDataLoader></vDataLoader>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <servicecode
        v-if="ModalShow"
        v-on:closemodal="closeModal"
        :show-modal="ModalShow"
        :servicecode="serviceCodeData"
        :is_edit="is_edit"
      ></servicecode>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_SERVICECODES,
  CREATE_SERVICECODES
} from "@/core/services/store/actions.type";
import servicecode from "@/pages/backend/servicecodes/servicecode";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";

export default {
  computed: {
    ...mapGetters(["currentUser", "getServiceCodeData"]),
    filteredServiceCode() {
      let tempServiceCode = this.getServiceCodeData;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempServiceCode = tempServiceCode.data.filter(item => {
          return (
            item.service_name
              .toUpperCase()
              .includes(this.searchInput.toUpperCase()) ||
            item.code.toUpperCase().includes(this.searchInput.toUpperCase())
          );
        });
      } else {
        tempServiceCode = tempServiceCode.data;
      }
      return tempServiceCode;
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getServiceCode();
  },
  components: {
    servicecode,
    vDataLoader
  },
  methods: {
    showModal() {
      this.serviceCodeData = {
        id: 0,
        service_name: "",
        code: "",
        days: "",
        default_markup: "",
        default_tax: ""
      };
      this.ModalShow = true;
      this.is_edit = false;
    },
    closeModal() {
      this.ModalShow = false;
      this.$store.dispatch(FETCH_SERVICECODES);
    },
    edit(item) {
      this.ModalShow = true;
      this.is_edit = true;
      this.serviceCodeData = {
        ...item
      };
    },
    update(item) {
      let data = { ...item };
      this.$store
        .dispatch(CREATE_SERVICECODES, data)
        .then(() => {
          this.$toastr.s("Updated Successfully  ");
          this.$store.dispatch(FETCH_SERVICECODES);
        })
        .catch(err => {
          this.errors = err;
        });
    },
    getServiceCode() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_SERVICECODES)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    }
  },
  data() {
    return {
      loadingData: false,
      ModalShow: false,
      columns: [
        {
          name: "ServiceCodes",
          width: "",
          label: "name ",
          class: ""
        }
      ],
      searchInput: "",
      is_edit: false,
      serviceCodeData: {
        id: 0,
        service_name: "",
        code: "",
        days: "",
        default_markup: "",
        default_tax: ""
      },
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Service code List",
          route: ""
        }
      ]
    };
  }
};
</script>
